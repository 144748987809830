import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-models"
export default class extends Controller {
  clearFilter() {
    this.element.querySelectorAll('a').forEach(x => {
      x.classList.remove("btn-primary");
      x.classList.add('btn-outline-primary');
    });
  }

  toggleFilter(event) {
    this.clearFilter()
    event.srcElement.classList.remove("btn-outline-primary");
    event.srcElement.classList.add('btn-primary')
  }
}
