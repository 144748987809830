import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import Tribute from "tributejs"

// Connects to data-controller="mentions"
export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchUsers,
      // searchOpts: {
      //   pre: '<span>',
      //   post: '</span>',
      //   skip: false // true will skip local search, useful if doing server-side search
      // },
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this) // Replace with custom function.
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    // fetch(`/mentions.json?q=${text}`) // The client side search tributejs has is ok, perhaps use this.
    fetch(`/mentions.json?q%5Bname_cont%5D=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))

      // TODO: Use request.js ?
      // get(`/mentions?q=${text}`, {
      //   responseKind: 'json'
      // });
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      contentType: "x-application/user",
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  // Default function does not replace search text.
  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
    this.editor.deleteInDirection("backward");
  }
}
