import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accept-turbo-stream"
export default class extends Controller {
  connect() {
    // To make get requests able to respond with turbo streams.
    // https://github.com/hotwired/turbo-site/pull/40#issuecomment-791487001
    addEventListener("turbo:before-fetch-request", (event) => {
      const { headers } = event.detail.fetchOptions || {}
      if (headers) {
        headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ")
      }
    });
  }
}
