import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="editing"
export default class extends Controller {
  static values = {
    model: String,
    modelid: String
  }

  poll() {
    // TODO: Enable again. can we disable only in dev?
    // post('/editing', {
    //   responseKind: 'turbo-stream',
    //   body: {
    //     model: this.modelValue,
    //     modelid: this.modelidValue
    //   }
    // });
  }

  connect() {
    this.interval = setInterval(() => {
      this.poll();
    }, 5000);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
