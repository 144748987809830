import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hotkeys"
export default class extends Controller {
  submitForm(event, form) {
    event.preventDefault();
    form.requestSubmit();
  }

  trySubmitForm(event) {
    let allForms = document.querySelectorAll('form');
    if (!allForms.length) return;
    if (allForms.length == 1) { // Check if one form
      this.submitForm(event, allForms[0]);
      return;
    }

    let parentForm = event?.srcElement?.closest('form');
    if (parentForm) { // Check if parent has form
      this.submitForm(event, parentForm);
      return;
    }

    let modalForm = document.querySelector('#applicationModal form');
    if (modalForm) { // Check if modal has form
      this.submitForm(event, modalForm);
      return;
    }

    allForms.forEach(form => {
      if (window.location.href.includes(form.action)) { // Check if url contains form action
        this.submitForm(event, form);
      }
    });
  }

  tryHotkey(event) {
    if (event.ctrlKey && event.key === 's') {
      this.trySubmitForm(event);
    }
  }

  // https://stimulus.hotwired.dev/reference/actions
  // data-action="keydown.ctrl+s->hotkeys#submitForm:prevent" does not work
  // TODO: Maybe update stimulus and try again?
}
