import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="org-contact-options"
export default class extends Controller {
  static targets = ["contact_select"]
  static values = {
    url: String
  }

  // static values = {
  //   param: String,
  //   url: String
  // }

  change_contact_options(event) {
    let params = new URLSearchParams()
    params.append("target", this.contact_selectTarget.id)
    // Alternatively set data-org-contact-options-param-value="organizations_brand_id" and use this.paramValue
    params.append("organizations_brand_id", event.target.selectedOptions[0].value)

    get(this.urlValue + '?' + params, { 
      responseKind: 'turbo-stream' 
    });
  }
}
