import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-select"
export default class extends Controller {
  static targets = ["select"];

  initialize() {
    this.optionsUnfiltered = [...this.selectTarget.children];
  }

  filterOptions(event) {
    let searchStr = event.srcElement.value;
    let matchedOptions = []

    for (let item of this.optionsUnfiltered) {
      if (item.innerText.includes(searchStr)) {
        matchedOptions.push(item)
      }
    }
    this.selectTarget.replaceChildren(...matchedOptions)
  }
}
