import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["select", "input"];

  // Reset form after submit.
  reset(event) {
    if (event.detail.success) {
      this.element.reset();
    }
  }

  // Remove option from select after submit because of unique constraint.
  clearOption(event) {
    if (event.detail.success) {
      const sel = this.selectTarget;
      sel.options[sel.selectedIndex].remove();
    }
  }

  // Clear input type file if append is used, so it does not get reuploaded every submit.
  clearInput(event) {
    if (event.detail.success) {
      const input = this.inputTarget;
      input.value = '';
    }
  }

  // Clear form errors on success.
  clearErrors(event) {
    if (event.detail.success) {
      const invalidInputs = event.srcElement.querySelectorAll('.is-invalid');
      invalidInputs.forEach(el => el.classList.remove('is-invalid'));

      const messages = event.srcElement.querySelectorAll('.form-error');
      messages.forEach(el => el.remove());
    }
  }
}
