import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    url: String
  }

  clearModal(event) {
    event.srcElement.querySelector('.modal-title').innerHTML = '';
    event.srcElement.querySelector('#applicationModalBody').innerHTML = '';
  }

  getModalContent(event) {
    get(this.urlValue, {
      responseKind: 'turbo_stream'
    });
  }
}
