import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="notifications"
export default class extends Controller {
  markAsViewed() {
    if(!document.querySelector('#notification_count_badge .count-badge')) return;

    post('/notifications/mark_as_viewed', {
      responseKind: 'turbo-stream'
    });
  }
}
